.nav-link {
  margin-right: 30px;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 14px;
}
body {
  background-color: #1b1b2f;
  font-family: montserrat;
}
.form-group {
  margin-top: 20px;
}
.welcome .welButton {
  font-size: 16px;
}
.welButton,
.btnSignin {
  background-color: #e43f5a;
  color: white;
}
tr {
  margin-top: 5px;
  margin-bottom: 5px;
}
.profile {
  font-size: 14px;
}
.jumbotron-fluid {
  background-image: url(../images/jerry-wang-Pvf_WTiFGng-unsplash.jpg);
  background-size: cover;
  width: 100%;
  height: 460px;
  background-position: 0;
  background-repeat: no-repeat;
  /* margin-left: -10px; */
  /* padding-left: -12px; */
}

.content {
  color: white;
}
.WelContainer h2 {
  color: white;
  text-shadow: 0 0 3px grey;
}
.footer-text {
  font-size: 12px;
  text-align: center;
}
.news {
  width: 100%;
  height: fit-content;
  background-color: hsla(0, 0%, 0%, 0.2);
  border-radius: 20px;
  padding-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
}
.covDet {
  border-radius: 30px !important;
}
@media (min-width: 992px) {
  .nav-link {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: normal;
  }
  .welcome,
  .signin {
    height: 70vmin;
    width: 70vmin;
    background-color: hsla(0, 0%, 0%, 0.2);
    border-radius: 20px;
  }
  .content {
    height: 80vmin;
    width: 80vmin;
    background-color: hsla(0, 0%, 0%, 0.2);
    border-radius: 20px;
  }
  .profile {
    font-size: 16px;
  }
  .jumbotron {
    height: 540px;
  }
  .footer-text {
    font-size: 16px;
    text-align: left;
  }
}

/** Country Name **/
.nav-country {
  color: white;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
  padding-right: 10px;
}
.nav-country:hover {
  color: #1b1b2f;
}
.list-country {
  background-color: #e43f5a;
}
/* profile */
.avatar {
  border-radius: 50%;
}

/* footer */
.icon-socmed {
  width: 5vmin;
}


/* not found */
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#notfound {
  position: relative;
  height: 100vh;
  background: #030005;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 767px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
}

.notfound .notfound-404 {
  position: relative;
  height: 180px;
  margin-bottom: 20px;
  z-index: -1;
}

.notfound .notfound-404 h1 {
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50% , -50%);
  -ms-transform: translate(-50% , -50%);
  transform: translate(-50% , -50%);
  font-size: 224px;
  font-weight: 900;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: -12px;
  color: #030005;
  text-transform: uppercase;
  text-shadow: -1px -1px 0px #8400ff, 1px 1px 0px #ff005a;
  letter-spacing: -20px;
  animation-name: NOTFOUND;
}

.notfound .notfound-404 {
  -webkit-animation: NOTFOUND 13s ease infinite;
  -moz-animation: NOTFOUND 13s ease infinite;
  animation: NOTFOUND 13s ease infinite;

}
@-webkit-keyframes NOTFOUND {
  0%{background-position:11% 0%}
  50%{background-position:90% 100%}
  100%{background-position:11% 0%}
}
@-moz-keyframes NOTFOUND {
  0%{background-position:11% 0%}
  50%{background-position:90% 100%}
  100%{background-position:11% 0%}
}
@keyframes NOTFOUND {
  0%{background-position:11% 0%}
  50%{background-position:90% 100%}
  100%{background-position:11% 0%}
}



.notfound .notfound-404 h2 {
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  left: 0;
  right: 0;
  top: 110px;
  font-size: 42px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0px 2px 0px #8400ff;
  letter-spacing: 13px;
  margin: 0;
}

.notfound a {
  font-family: 'Montserrat', sans-serif;
  display: inline-block;
  text-transform: uppercase;
  color: #ff005a;
  text-decoration: none;
  border: 2px solid;
  background: transparent;
  padding: 10px 40px;
  font-size: 14px;
  font-weight: 700;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound a:hover {
  color: #8400ff;
}

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 h2 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 h1 {
    font-size: 182px;
  }
}